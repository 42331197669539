import api from "./api";
import TokenService from "./token.service";
import UtilsService from "@/services/utils.service";

class AuthService {
  async login({ email, password }) {
    var data = {
      client_id: 2,
      client_secret: "lFNK11weWnJBrOFT6caNuM4O3Sd3zDm5nqXpb3oO",
      grant_type: "password",
      username: email,
      scope: "*",
      password: password,
    };

    let token_res = await api.post("/oauth/token", data);
    TokenService.setUser(token_res.data);

    let user_res = await UtilsService.getAuthUser();
    let user = JSON.parse(localStorage.getItem("user"));
    user.id = user_res.data.data.id;
    user.email = user_res.data.data.email;
    let user_details = await UtilsService.getuserdetails(user_res.data.data.id);
    user.client_id = user_details.data.data.client_id;
    TokenService.setUser(user);
    await this.info(user_details.data.data);

    return user_details.data.data;
  }

  async signup(data) {
    return api.post(`/signups`, data);
  }
  async info(user_details) {
    TokenService.setContact(user_details);
    if (user_details.subsidiaries) {
      let with_me = user_details.subsidiaries;
      with_me.push(user_details.client_info);
      // console.log(with_me);
      TokenService.setSubsidiaries(with_me);
    }
    localStorage.setItem("client_dept", 0);
    localStorage.setItem("client_sub_dept", 0);
  }
  async logout() {
    localStorage.clear();
  }
}

export default new AuthService();
