import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

/* css dependencies */
require("@/assets/css/bootstrap.min.css");
require("@/assets/vendor/fontawesome-free/css/all.min.css");
require("@/assets/css/atlantis.min.css");
require("@/assets/css/atlantis.css");
require("@/assets/css/fonts.min.css");
require("@/assets/css/bootstrap-datepicker.css");
require("@/assets/js/plugin/webfont/webfont.min.js");
require("@/assets/css/fonts.min.css");

/* JS dependencies */
// <!-- jQuery core -->
require("@/assets/js/core/jquery.3.2.1.min.js");
require("@/assets/js/core/popper.min.js");
require("@/assets/js/core/bootstrap.min.js");

// <!-- jQuery UI -->
require("@/assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min.js");
require("@/assets/js/plugin/jquery-ui-touch-punch/jquery.ui.touch-punch.min.js");

//Datatable Modules
require("@/assets/dataTables/css/dataTables.responsive.css");
require("@/assets/dataTables/css/buttons.dataTables.min.css");
require("@/assets/js/plugin/datatables/datatables.min.js");
require("@/assets/dataTables/js/dataTables.responsive.js");
require("@/assets/dataTables/js/dataTables.buttons.min.js");
require("@/assets/dataTables/js/dataTables.responsive.js");
require("@/assets/dataTables/js/dataTables.responsive.js");
//datatable buttons
require("@/assets/dataTables/js/dataTables.buttons.min.js");
require("@/assets/dataTables/js/jszip.min.js");
// require("@/assets/dataTables/js/pdfmake.min.js");
require("@/assets/dataTables/js/vfs_fonts.js");
require("@/assets/dataTables/js/buttons.html5.min.js");
require("@/assets/dataTables/js/buttons.print.min.js");
require("@/assets/dataTables/js/buttons.colVis.min.js")

require("@/assets/js/plugin/chart.js/chart.min.js");
//datepicker
require("@/assets/js/bootstrap-datepicker.js");

require("@/assets/js/atlantis.min.js");
require("@/assets/js/atlantis.js");
require("@/assets/js/atlantis2.js");

import WebFont from "./assets/js/plugin/webfont/webfont.min";
import VueGoogleAutocomplete from "vue-google-autocomplete";

// console.log(WebFont)
WebFont.load({
  google: { families: ["Lato:300,400,700,900"] },
  custom: {
    families: [
      "Flaticon",
      "Font Awesome 5 Solid",
      "Font Awesome 5 Regular",
      "Font Awesome 5 Brands",
      "simple-line-icons",
    ],
  },
  active: function () {
    sessionStorage.fonts = true;
  },
});


import { FontAwesomeIcon } from "./plugins/font-awesome";

import setupInterceptors from "./services/setupInterceptors";
// import reports from './components/reports'


setupInterceptors(store);

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
